<template>
  <div>
    <div v-if="suggestedValue != 0 && floatOpen" class="donation-potential">
      <v-layout row>
        <v-flex py-2 px-3 @click="openSimulation">
          <span>Seu potencial de doação é:</span>
          <div
            class="value"
            :title="'Seu potencial de doação é: R$ '+formatterHelper.formatMoney(suggested, 0)"
          >
            R$ {{formatterHelper.formatShortNumber(suggested, 1)}}
            <Icon name="fal fa-calculator-alt ml-1" size="16" colorFont="#fff" />
          </div>
        </v-flex>
        <v-flex skhrink @click="close">
          <a class="btn-close">
            <Icon name="fas fa-times ml-1" size="20" colorFont="#fff" />
          </a>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script type="plain/text">
import FormatterHelper from "@/scripts/helpers/formatter.helper";
export default {
  props: {
    dialog: true,
    valueToDiscount: 0,
    suggestedValue: 0
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      floatOpen: true
    };
  },
  created() {},
  computed: {
    suggested() {
      return this.suggestedValue;
    }
  },
  methods: {
    getSuggestedValueFromSessionStorage() {
      this.suggestedValue = sessionStorage.getItem("result");
    },
    calculateSuggestedValue() {
      if (this.suggestedValue - this.valueToDiscount >= 0)
        this.suggestedValue = this.suggestedValue - this.valueToDiscount;
      else this.suggestedValue = 0;
      this.$emit("suggestedValue", this.suggestedValue);
    },
    openSimulation() {
      this.$emit("openSimulation", "Recalcular");
    },
    close() {
      this.floatOpen = false;
      this.$emit("close");
    }
  },
  watch: {
    valueToDiscount() {
      this.calculateSuggestedValue();
    }
  }
};
</script>